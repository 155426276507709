import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { ShowPage } from "../enums/PanelType";
import AddOrChangeNode from "./AddOrChangeNode";

const OptionsMenu = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [showModal, setShowModal] = useState(false);

    const handleContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        if(determineIfShowMenu(event)) {
            setShowMenu(true);
            setMenuPosition({ x: event.clientX, y: event.clientY });
        }
    };
  
    const handleClick = () => {
        setShowMenu(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        document.addEventListener('contextmenu', handleContextMenu);
    }, []);
    
    const addObject = () => {
        setShowModal(true);
    };
    const addRelation = () => {
        
    };

    return (
        <>
          {showMenu && (
                <div className="context-menu" style={{ top: menuPosition.y, left: menuPosition.x, position: 'absolute', zIndex: 10 }}>
                    <ButtonGroup vertical>
                        <Button variant="secondary" onClick={addObject}>Object toevoegen</Button>
                        <Button variant="secondary" onClick={addRelation}>Relatie toevoegen</Button>
                    </ButtonGroup>
                </div>
            )}
            <AddOrChangeNode showModal={showModal} setShowModal={setShowModal} showPage={ShowPage.AddNode} />
        </>
      );
};

const determineIfShowMenu = (event: MouseEvent) => {
    const rightpanel = document.getElementsByClassName("offcanvas-end");

    // If rightpanel is visible determine of optionsMenu does not fall behind it.
    if(rightpanel[0] && rightpanel[0].getBoundingClientRect()) {
        const rect = rightpanel[0].getBoundingClientRect();
        const rightpanelX = rect.left + window.scrollX;
        if(event.clientX > rightpanelX) {
            return false;
        }
    }
    return true;
}

export default OptionsMenu;