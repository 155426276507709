
import { MutableRefObject, Ref, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import "@fortawesome/fontawesome-free/css/all.min.css";
import useApi from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import useNetwork from '../../hooks/useNetwork';
import { SelectionTool } from '../../enums/SelectionTool';
import "./SelectionMenu.css"

const SelectionMenuBottom = () => {
    const { selectionTool, setSelectionTool } = useNetwork();

    const updateSelectionTool = (tool: SelectionTool) => {
        setSelectionTool(tool);
    }

    return (
         <div className='position-absolute py-2' style={{ left: 0, right: 0, marginInline: "auto", zIndex: 5, bottom: 0, width: "fit-content"}} >
                <div className='position-relative mx-auto d-flex flex-row align-items-center shadow secondary-bg-color-grey rounded p-2'>
                    <div className={`primary-bg-color-grey rounded p-2 ${selectionTool === SelectionTool.Select ? 'selected-tool' : 'hovered-tool'}`} onClick={() => updateSelectionTool(SelectionTool.Select)} aria-placeholder='Select tool'>
                        <i className="fa-solid fa-arrow-pointer text-dark ft-m" style={{minWidth: '40px', textAlign: "center"}}></i>
                    </div>
                    <div className={`primary-bg-color-grey rounded p-2 ms-2 ${selectionTool === SelectionTool.NewRelation ? 'selected-tool' : 'hovered-tool'}`} onClick={() => updateSelectionTool(SelectionTool.NewRelation)} aria-placeholder='Create new relation'>
                        <i className="fa-solid fa-code-pull-request text-dark ft-m" style={{minWidth: '40px', textAlign: "center"}}></i>
                    </div>
                    <div className={`primary-bg-color-grey rounded p-2 ms-2 ${selectionTool === SelectionTool.NewObject ? 'selected-tool' : 'hovered-tool'}`} onClick={() => updateSelectionTool(SelectionTool.NewObject)} aria-placeholder='Create new object'>
                        <i className="fa-solid fa-code-commit text-dark ft-m" style={{minWidth: '40px', textAlign: "center"}}></i>
                    </div>
                </div>
        </div>
    );
};


export default SelectionMenuBottom;