
import { MutableRefObject, Ref, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Search } from 'react-bootstrap-icons'
import useApi from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';

interface SearchMenuTopProps {
    setShowRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchTopMenu: React.FC<SearchMenuTopProps> = ({ setShowRightPanel }) => {
    const [query, setQuery] = useState('');
    const api = useApi();

    useEffect(() => {
        setSearchResultsVisible(query.length > 0);
    }, [query]);

    const [results, setResults] = useState<any[]>([]);
    useEffect(() => {
        if (query.length > 0) {
            getSearchResults(query);
        }
    }, [query]);
    const getSearchResults = async (query: string) => {
        const res = await api.get(`search/nodes?q=${query}`);
        setResults(res.data);
    }

    const [searchResultsVisible, setSearchResultsVisible] = useState(false);
    return (
        <div >
            <SearchForm query={query} setQuery={setQuery} setShowRightPanel={setShowRightPanel} />
            <SearchResults results={results} setShowRightPanel={setShowRightPanel} visible={searchResultsVisible} setVisible={setSearchResultsVisible} />
        </div>
    );
};

function SearchForm({ query, setQuery, setShowRightPanel }: { query: string, setQuery: (query: string) => void, setShowRightPanel: any }) {
    const inputRef = useRef<HTMLInputElement>(null);

    return (<form method='get' onSubmit={(e) => {
        e.preventDefault();
        if (inputRef.current) {
            setQuery(inputRef.current.value)
        }
    }}
        className='position-absolute py-2' style={{ left: 0, right: 0, marginInline: "auto", zIndex: 10 }} >
        <div className='col-lg-4 position-relative d-flex flex-row align-items-center  mx-auto'>

            <Search size={20} className='position-absolute' style={{ left: 10 }} />
            <input ref={inputRef} type='text' onClick={() => { setShowRightPanel(false) }}
                onChange={(e) => setQuery(e.target.value)} placeholder='Search for nodes' className='outline-0 rounded-pill shadow bg-secondary-subtle border-0 w-100 py-2 ps-5' style={{ top: 0, outline: 0 }} />
        </div>

    </form>)
}

function SearchResults({ results, visible, setVisible, setShowRightPanel }: { results: any[], visible: boolean, setVisible: any, setShowRightPanel: any }) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setVisible);
    const navigate = useNavigate();
    return (
        results.length > 0 && visible ? <div className='position-absolute h-50' style={{ top: "50px", left: 0, right: 0, marginInline: "auto", zIndex: 10 }}>
            <div ref={wrapperRef} className='col-lg-4 bg-secondary-subtle rounded mt-4 mx-auto shadow d-flex flex-column gap-0 overflow-auto h-100'>
                {results.map((result, index) => {

                    return <div key={index} className='d-flex flex-column border-1 border-bottom cursor-pointer search-result p-3 gap-2' onClick={() => { setShowRightPanel(true); setVisible(false); navigate(`/object/${result.id}`) }}>
                        <div className='d-flex flex-row justify-content-between align-items-start gap-3'>
                            <div>{result.label}</div>
                            <div className='rounded bg-secondary text-white py-1 px-2'>{result.type}</div>
                        </div>
                        <div style={{ fontSize: 12 }}>{result.description}</div>
                    </div>
                })}
            </div>
        </div> : null
    )

}

function useOutsideAlerter(ref: MutableRefObject<any>, setVisible: any) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setVisible(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default SearchTopMenu;