import Offcanvas from 'react-bootstrap/Offcanvas';

interface OffCanvasExampleProps {
  show: boolean;
  [key: string]: any;
}

// onMouseOut={() => props.showFunction(false)}>

function XMenu({ show, ...props }: OffCanvasExampleProps) {

    return (
      <>
        <Offcanvas className="secondary-bg-color-grey" style={{ marginLeft: document.getElementById('left-menu-panel')?.offsetWidth || 40, zIndex: 14}} show={show}  {...props} backdrop={false}>
          <Offcanvas.Header closeButton onClick={() => props.showFunction(false)} >
            <Offcanvas.Title>Instellingen</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            Instellingen
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  
}

export default XMenu;