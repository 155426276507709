import { api } from '../utils/axios';
import { useEffect } from 'react';
import useAuth from './useAuth';

const useApi = () => {
    // for future use when not retrieving token from localstorage
    const { token } = useAuth();

    useEffect(() => {
        const requestIntercept = api.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']){
                    // Should use token from useAuth hook instead of localstorage
                    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
                }
                return config;
            }, (err) => Promise.reject(err)
        );

        // Add responseInterceptor that also uses refreshTokenHook

        return () => {
            api.interceptors.request.eject(requestIntercept);
        };

    },[token])

    return api;
}

export default useApi;