import { useEffect, useState } from "react";
import { Button, Col, Container, ListGroup, Offcanvas, Row, Spinner } from "react-bootstrap";
import useNetwork from "../../hooks/useNetwork";
import useApi from "../../hooks/useApi";
import { DataSet } from "vis-data";
import { useLocation, useParams } from 'react-router-dom';
import RemoveButton from "../RemoveButton";
import AddOrChangeNode from "../AddOrChangeNode";
import { ShowPage } from "../../enums/PanelType";

const URL = '/object/';

interface RightPanelProps {
    showRightPanel: boolean;
    setShowRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
  }

const NodeInformation: React.FC<RightPanelProps> = ({ showRightPanel, setShowRightPanel }) => {

    const [isLoading, setIsLoading] = useState(true);

    const { selection } = useNetwork();
    const api = useApi();

    const [label, setLabel] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    let { objectId } = useParams<{ objectId: string }>();
    const [totalAmountOfEdges, setTotalAmountOfEdges] = useState<number>(0);
    const [totalAmountOfIncomingEdges, setTotalAmountOfIncomingEdges] = useState<number>(0);
    const [totalAmountOfOutgoingEdges, setTotalAmountOfOutgoingEdges] = useState<number>(0);
    const [degree, setDegree] = useState<number>(0);
    const [closeness, setCloseness] = useState<number>(0);
    const [eigenvector, setEigenvector] = useState<number>(0);
    const [betweenness, setBetweenness] = useState<number>(0);
    const [date, setDate] = useState<string>('');
    const [author, setAuthor] = useState<string>('');
    const [topics, setTopics] = useState<DataSet<{}>>(new DataSet<{}>([]));
    const [sources, setSources] = useState<DataSet<{}>>(new DataSet<{}>([]));
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSectionTopics, setShowSectionTopics] = useState(false);
    const [showSectionSources, setShowSectionSources] = useState(false);
    const [res_current, setObject] = useState<any>(null);
    const [res_connection, setConnections] = useState<any>(null);

    const handleClose = () => setShowRightPanel(false);



    useEffect(() => {
        api.get(URL + objectId).then((res) => {
            setObject(res);
        }).catch((err) => {
            console.log(err);
        });
    }, [objectId]);

    useEffect(() => {
        api.get(URL + objectId + '/connections').then((res) => {
            setConnections(res);
        }).catch((err) => {
            console.log(err);
        });
    }, [res_current]);

    useEffect(() => {
            setIsLoading(true);

        try {

            // Clear topics and sources to prevent duplicates
            topics.clear();
            sources.clear();

            // Set label and type
            res_current.data.label ? setLabel(res_current.data.label) : setLabel('...');
            setType(setTypeForNode(res_current.data.type));

            // Set centralities
            res_current.data.centralityDegree ? setDegree(res_current.data.centralityDegree) : setDegree(0);
            res_current.data.centralityBetweenness ? setBetweenness(res_current.data.centralityBetweenness) : setBetweenness(0);
            res_current.data.centralityCloseness ? setCloseness(res_current.data.centralityCloseness) : setCloseness(0);
            res_current.data.centralityEigenvector ? setEigenvector(res_current.data.centralityEigenvector) : setEigenvector(0);

            // Format creation date
            const index = res_current.data.created.indexOf("T");
            const trimmedString = index !== -1 ? res_current.data.created.substring(0, index) : res_current.data.created;
            res_current.data.created ? setDate(trimmedString) : setDate('...');

            // Set author
            res_current.data.author ? setAuthor(res_current.data.author) : setAuthor('');

            // Set description
            res_current.data.description ? setDescription(res_current.data.description) : setDescription('N.t.b.');

            res_connection.data.inc_count ? setTotalAmountOfIncomingEdges(res_connection.data.inc_count) : setTotalAmountOfIncomingEdges(0);
            res_connection.data.out_count ? setTotalAmountOfOutgoingEdges(res_connection.data.out_count) : setTotalAmountOfOutgoingEdges(0);

            let total = 0;
            if (res_connection.data.inc_count > 0) {
                total += res_connection.data.inc_count;
            }
            if (res_connection.data.out_count > 0) {
                total += res_connection.data.out_count;
            }

            setTotalAmountOfEdges(total);

            for (const node of res_connection.data.inc_nodes) {
                if (node.type === 'Topic') {

                    topics.add(node);

                } else if (node.type === 'Document') {

                    sources.add(node);

                }
            }

            for (const node of res_connection.data.out_nodes) {
                if (node.type === 'Topic') {

                    topics.add(node);

                } else if (node.type === 'Document') {

                    sources.add(node);

                }
            }

            // set section 2 hidden if topics has length 0
            topics.length >= 1 ? setShowSectionTopics(true) : setShowSectionTopics(false);

            // set section 3 hidden if sources has length 0
            sources.length >= 1 ? setShowSectionSources(true) : setShowSectionSources(false);

            // set Datasets
            setTopics(new DataSet([...topics.get()]));
            setSources(new DataSet([...sources.get()]));

        } catch (err) {

            console.log(err);

        } finally {

            setIsLoading(false);

        }

    }, [res_connection]);

    function setTypeForNode(input: string) {

        // translate node labels
        switch (input) {
            case 'owl:Topic':
                return 'Onderwerp';

            case 'owl:Causality':
                return 'Causaliteit';

            case 'owl:Correlation':
                return 'Correlatie';

            case 'owl:Impact':
                return 'Impact';

            case 'owl:Document':
                return 'Document';

            case 'owl:Capability':
                return 'Bekwaamheid';

            case 'owl:BroadCapability':
                return 'Brede Capaciteit';

            case 'owl:Phenomenon':
                return 'Fenomeen'; 

            case 'owl:OperationalCapability':
                return 'Operationele Capaciteit';

            default:
                return '...';
        }
    };

    return (
        <>
            {isLoading ? (<Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh', color: '#BFBFBF' }}>
                <Spinner animation="border" />
            </Container>) : (
                <>
                    <Offcanvas.Header style={{ background: '#BFBFBF', paddingRight: '44px', paddingBottom: '0px' }} closeButton></Offcanvas.Header>
                    <Offcanvas.Header style={{ background: '#BFBFBF', paddingTop: '0px' }}>
                        <Offcanvas.Title><h3 style={{ padding: '12px' }}>{label}</h3></Offcanvas.Title><br />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Container fluid >
                            <Row className='p-3' style={{ background: '#BFBFBF' }}>
                                <Container>
                                    <Row>
                                        <Col xs={5}>Type:</Col>
                                        <Col xs={5} className="text-end">{type}</Col>
                                        <Col xs={2}></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={5}>Relaties (tot/in/uit):</Col>
                                        <Col xs={5} className="text-end">{totalAmountOfEdges} / {totalAmountOfIncomingEdges} / {totalAmountOfOutgoingEdges}</Col>
                                        <Col xs={2}></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={5}>Degree:</Col>
                                        <Col xs={5} className="text-end">{degree}</Col>
                                        <Col xs={2}></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={5}>Betweenness:</Col>
                                        <Col xs={5} className="text-end">{betweenness}</Col>
                                        <Col xs={2}></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={5}>Closeness:</Col>
                                        <Col xs={5} className="text-end">{closeness}</Col>
                                        <Col xs={2}></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={5}>Eigenvector:</Col>
                                        <Col xs={5} className="text-end">{eigenvector}</Col>
                                        <Col xs={2}></Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={5}>Datum:</Col>
                                        <Col xs={5} className="text-end">{date}</Col>
                                        <Col xs={2}></Col>
                                    </Row>
                                    {author !== '' && (<Row>
                                        <Col xs={5}>Auteur:</Col>
                                        <Col xs={5} className="text-end">{author}</Col>
                                        <Col xs={2}></Col>
                                    </Row>)}
                                </Container>
                            </Row>

                            <Row className='p-3 mt-1' style={{ background: '#EAEAEA' }}>
                                <Container>
                                    <Row><h5>Beschrijving:</h5></Row>
                                    <Row><p>{description}</p></Row>
                                </Container>
                            </Row>

                            {showSectionTopics && (
                                <Row className='p-3 mt-1' style={{ background: '#EAEAEA' }}>
                                    <Container>
                                        <Row>
                                            <h5>Topics:</h5>
                                        </Row>
                                        <Row>
                                            <ListGroup>
                                                {topics.map((item: any) => (
                                                    <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center" style={{ background: '#EAEAEA', border: 'none', paddingLeft: '12px' }}>{item.label}
                                                        <Button className="p-0" style={{ background: '#EAEAEA', border: 'none' }}><span className="three-dots">⋮</span></Button>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </Row>
                                    </Container>
                                </Row>
                            )}

                            {showSectionSources && (
                                <Row className='p-3 mt-1' style={{ height: '100%', overflowY: 'auto', background: '#EAEAEA' }}>
                                    <Container>
                                        <Row>
                                            <h5>Bronnen:</h5>
                                        </Row>
                                        <Row>
                                            <ListGroup>
                                                {sources.map((item: any) => (
                                                    <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center" style={{ background: '#EAEAEA', border: 'none', paddingLeft: '12px' }}>{item.label}
                                                        <Button className="p-0" style={{ background: '#EAEAEA', border: 'none' }}><span className="three-dots">⋮</span></Button>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </Row>
                                    </Container>
                                </Row>
                            )}
                            <RemoveButton setShowRightPanel={setShowRightPanel}/>
                            <button onClick={() => setShowModal(true)}>Node aanpassen</button>
                            <AddOrChangeNode showModal={showModal} setShowModal={setShowModal} showPage={ShowPage.ChangeNode} />
                        </Container>
                    </Offcanvas.Body>
                </>
            )}
        </>
    );
};

export default NodeInformation;