import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useApi from "../hooks/useApi";

const URL = '/user_info';

const Persistend = () => {
   const [ isLoading, setIsLoading ] = useState(true);
   const { token, setUserInformation, removeUserInformation } = useAuth();
   const api = useApi();

   useEffect(() => {
    const verifyUser = async () => {
        try{
            
            //Localstorage is not safe in the future use a refresh token that is stored in a HTTP only cookie.
            //useRefreshHook to get a new access token via the HTTP only cookie.
            const res = await api.get(URL);

            const user = res.data.email;
            const role = res.data.extra.role;
            const token = localStorage.getItem('token');
            
            setUserInformation(user, role, token as string);

        }catch(err) {
            removeUserInformation();
            console.log(err);

        }finally{
            setIsLoading(false);
        }
    }

    !token ? verifyUser() : setIsLoading(false);

   },[removeUserInformation, setUserInformation]);

   return (
    <> { isLoading ? <p>Is aan het laden...</p> : <Outlet /> } </>
   );
};

export default Persistend;