export enum OntologyNaming {
    IMPACT = "owl:impact",
    SOURCE = "owl:source",
    CAPABILITY = "owl:capability",
    SAME_AS = "owl:sameAs",
    HAS_EFFECT = "owl:hasEffect",
    HAS_CAUSE = "owl:hasCause"
}

export const OntologyNamingDetails = {
    "owl:impact": "Has Impact on",
    "owl:source": "Source",
    "owl:capability": "Capability",
    "owl:sameAs": "Same as",
    "owl:hasCause": "Has Cause",
    "owl:hasEffect": "Has Effect",
};