import {OntologyNaming, OntologyNamingDetails} from "../enums/OntologyNaming";

export function setLabelForEdge(input: string) {
    // translate edge labels
    const ontologyName = OntologyNamingDetails[input as keyof typeof OntologyNamingDetails]
    if (ontologyName) return ontologyName;
    return input;
}

export function setLabelForNode(input: string, words: number = 4) {
    // split the line into seperate words, bring amount to four words, add linebreak after two words and add dots when length is greater than four.
    const parts = (input as string).split(' ');
    let index = 0;
    let label = '';

    if (parts.length > words) {
        while (index < words) {
            if (parts[index]) {
                label = label + parts[index] + ' ';
            }
            if (index === 1) {
                label = label + '\n';
            }
            index++;
        }
        label = label.trim() + '...'
    } else {
        while (index < parts.length) {
            if (parts[index]) {
                label = label + parts[index] + ' ';
            }
            if (index === 1) {
                label = label + '\n';
            }
            index++;
        }
        label = label.trim();
    }

    return label;
}
