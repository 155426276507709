import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import {
  createContext,
  useState,
} from "react";
import { GearFill, SearchHeart } from 'react-bootstrap-icons'
import "@fortawesome/fontawesome-free/css/all.min.css";
import LeftStatsPanel from "./LeftStatsPanel";
import { PanelType } from "../../enums/PanelType";
import "./Menu.css"

const URL = "/logout";
export const StatsPanelContext = createContext<any | undefined>(undefined);

const LeftMenuPanel = () => {
  const [showStatsPanel, setShowStatsPanel] = useState(false);
  const [panel_type, changePanelType] = useState(PanelType.Stats);

  const { removeUserInformation } = useAuth();
  // use api Hook to be able to do post and get requests
  const api = useApi();

  const handleLogout = async (e: React.FormEvent) => {
    try {
      await api.post(URL);
      removeUserInformation();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        id="left-menu-panel"
        // onMouseLeave={() => setShowStatsPanel(false)}
        className="primary-bg-color-grey col-lgs-1"
        style={{ maxWidth: "100px", zIndex: 15, position: "relative" }}
      >
        <div
          className="d-flex justify-content-center mb-2"
          style={{ marginTop: "30px" }}
        >
          <img
            src="/favicon.svg"
            alt="logo"
            width="40"
            height="40"
            className="img-fluid"
          />
        </div>
        <div
          className={
            showStatsPanel && panel_type == PanelType.Stats
              ? "secondary-bg-color-grey"
              : ""
          }
          style={{ height: "60px", marginTop: "60px" }}
          onMouseEnter={() => {
            setShowStatsPanel(true);
            changePanelType(PanelType.Stats);
          }}
        >
          <button className="w-100 h-100 d-flex align-items-center justify-content-center no-button-style">
            <i className="fa-solid fa-hexagon-nodes text-dark ft-m"></i>
          </button>
        </div>
        <div
          className={
            showStatsPanel && panel_type == PanelType.Settings
              ? "secondary-bg-color-grey mt-2"
              : "mt-2"
          }
          style={{ height: "60px" }}
          onMouseEnter={() => {
            setShowStatsPanel(true);
            changePanelType(PanelType.Settings);
          }}
        >
          <button className="w-100 h-100 d-flex align-items-center justify-content-center no-button-style">
            <GearFill className="text-dark ft-m" />
          </button>
        </div>
        <div className="w-100 d-flex align-self-end justify-content-center p-2 bottom-positioning">
          <button
            onClick={handleLogout}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              height: "40px",
            }}
          >
            <i className="fa-solid fa-right-from-bracket text-dark ft-s"></i>
          </button>
        </div>
        <LeftStatsPanel
          show={showStatsPanel}
          panel_type={panel_type || PanelType.Stats}
          showFunction={setShowStatsPanel}
        />
      </div>
      <></>
    </>
  );
};

export default LeftMenuPanel;
