import { MutableRefObject, Ref, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Network } from 'vis-network';
import { DataSet } from 'vis-data';
import useApi from '../../hooks/useApi';
import 'vis-network/styles/vis-network.css';
import useNetwork from '../../hooks/useNetwork';
import SearchTopMenu from '../search-menu/SearchTopMenu';
import { setLabelForEdge, setLabelForNode } from '../../helpers/helper_functions';
import { useNavigate } from 'react-router-dom';
import { SelectionTool } from '../../enums/SelectionTool';
import { KnowledgeGraphColors } from '../../enums/KnowledgeGraphColors';
import { options } from '../../settings/KnowledgeGraphSettings';
import CreateObject from './CreateObject';
import CreateRelation from './CreateRelation';
import { SelectionPanel } from '../../enums/SelectionPanel';
const URL = '/knowledge-graph';
const OBJECT_URL = '/object/';


let selectedActive = false;

interface KnowledgeGraphProps {
    setShowRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
    setTypeRightPanel: React.Dispatch<React.SetStateAction<SelectionPanel>>;
}

const KnowledgeGraph: React.FC<KnowledgeGraphProps> = ({ setShowRightPanel, setTypeRightPanel }) => {
    const { network, setNetwork, selection, setSelection, selectionTool, setSelectionTool } = useNetwork();

    const api = useApi();
    const navigate = useNavigate();
    const [apiCallResults, setApiCallResults] = useState<any>(null);
    const [nodeState, nodeUpdate] = useState('default');
    const [showNewObjectMenu, setShowObjectMenu] = useState(false);
    const [newObjectPosition, setNewObjectPosition] = useState({ "x": 0, "y": 0 });
    const [showNewRelation, setShowNewRelation] = useState(false);
    const [newRelationCreating, setNewRelationCreating] = useState({ "data_object_1": {}, "data_object_2": {}, "x": 0, "y": 0, "object_1": "", "object_2": "" });
    const [dataset, setDataset] = useState<{ "nodes": DataSet<{}>, "edges": DataSet<{}> }>({ "nodes": new DataSet<{}>([]), "edges": new DataSet<{}>([]) });
    const containerRef = useRef<HTMLDivElement>(null);

    const handleClick = (params: any) => {
        if (selectionTool === SelectionTool.NewObject) {
            handleClickNewObject(params);
        }
        if (params.nodes.length === 1) {
            if (selectionTool === SelectionTool.Select) {
                handleNodeClickSelection(params);
            }
            else if (selectionTool === SelectionTool.NewRelation) {
                handleClickNewRelation(params);
            }
        } else if (params.edges.length >= 1) {
            if (selectionTool === SelectionTool.Select) {
                handleEdgeClickSelection(params);
            }
        }
    };

    const handleClickNewRelation = (params: any) => {
        if (network)
            setSelection(network.getSelection());

        const id: number = params.nodes[0];
        const selected: any = dataset.nodes.get(id);

        if (dataset.nodes.length === 0 || id === undefined) {
            return;
        }
        if (newRelationCreating.object_1 === "") {
            api.get(OBJECT_URL + selected.uri).then((res) => {
                setNewRelationCreating({ ...newRelationCreating, object_1: selected.uri, data_object_1: res.data });
                updateNodesAndEdges(id);
            }).catch((err) => {
                console.log(err);
            });
        }
        else if (newRelationCreating.object_2 === "") {
            api.get(OBJECT_URL + selected.uri).then((res) => {
                setNewRelationCreating({ ...newRelationCreating, object_2: selected.uri, data_object_2: res.data, x: params.pointer.DOM.x, y: params.pointer.DOM.y });
                setShowNewRelation(true);
            }).catch((err) => {
                console.log(err);
            });
        }
    };

    const handleClickNewObject = (params: any) => {
        setShowObjectMenu(true)
        setNewObjectPosition({ "x": params.pointer.DOM.x, "y": params.pointer.DOM.y });
    };

    const handleNodeClickSelection = (params: any) => {
        const id: number = params.nodes[0];
        const selected: any = dataset.nodes.get(id);
        if (network)
            setSelection(network.getSelection());

        if (dataset.nodes.length === 0 || id === undefined) {
            return;
        }

        navigate(`/object/${selected.uri}`);
        setTypeRightPanel(SelectionPanel.NODE_INFORMATION);
        setShowRightPanel(true);
        updateNodesAndEdges(id);
    };

    const handleEdgeClickSelection = (params: any) => {
        const id: number = params.edges[0];
        const selected: any = dataset.edges.get(id);
        console.log(params, selected);
        if (selected.impact_id) {
            const selected: any = dataset.edges.get(id);
            setTypeRightPanel(SelectionPanel.EDGE_INFORMATION);
            navigate(`/edge/${selected.impact_id}`);
            setShowRightPanel(true);
            dataset.edges.updateOnly({ id: id, chosen: true })
        }
    }

    const updateNodesAndEdges = (id: any) => {
        if (network) {
            const ids = network.getConnectedNodes(id);
            dataset.nodes.forEach((node: any) => {
                let color = KnowledgeGraphColors.DEFAULT_NON_SELECTED;
                if (ids.includes(node.id)) {
                    color = KnowledgeGraphColors.SELECTED_OP_0_5;
                }
                if (node.id === id) {
                    color = KnowledgeGraphColors.SELECTED;
                }
                dataset.nodes.updateOnly({
                    id: node.id, label: node, title: node.title, uri: node.uri, color: {
                        background: color,
                        border: color,
                        highlight: {
                            background: color,
                            border: color
                        },
                        size: node.size
                    }
                });
            });

            const connected_edges = network.getConnectedEdges(id);
            connected_edges.forEach(edge => {
                dataset.edges.updateOnly({ id: edge, chosen: true })
            });
            // network.setData(dataset);
        }
    };

    useEffect(() => {
        api.get(URL).then((res) => {
            setApiCallResults(res);
        }).catch((err) => {
            console.log(err);
        });
    }, []);


    useEffect(() => {
        if (containerRef.current && apiCallResults) {
            let edges = new DataSet<{}>([]);
            let nodes = new DataSet<{}>([]);
            apiCallResults.data.nodes.forEach((item: any) => {
                if (!nodes.get(item.id)) {
                    let label = setLabelForNode(item.label);

                    nodes.add({
                        id: item.id, label: label, title: item.title, uri: item.uri, color: {
                            background: KnowledgeGraphColors.DEFAULT,
                            border: KnowledgeGraphColors.DEFAULT,
                            highlight: {
                                background: KnowledgeGraphColors.DEFAULT,
                                border: KnowledgeGraphColors.DEFAULT
                            },
                        },
                        size: 15
                    });
                }
            });

            apiCallResults.data.edges.forEach((item: any) => {
                const id = edges.length + 1;

                let label = setLabelForEdge(item.label);

                const tooltip = [
                    item.confidence != null ? `Sterkte: ${(item.strength * 100) / 10}%` : undefined,
                    item.confidence != null
                        ? `Zekerheid: ${(item.confidence * 100) / 10}%`
                        : undefined,
                    item.polarity != null
                        ? `Polariteit: ${item.polarity === "positive" ? "Positief" : "Negatief"}`
                        : undefined,
                ]
                    .filter(Boolean)
                    .join("\n");

                edges.add({
                    id: id, source: item.source, target: item.target, from: item.source_id, to: item.target_id, impact_id: item.impact_id, type: item.type,
                    label: label, title: tooltip ? tooltip : "test", polarity: item.polarity, strength: item.strength, confidence: item.confidence,
                    color: {
                        color:
                            item.polarity !== null
                                ? item.polarity === "positive"
                                    ? "#6BC581"
                                    : "#AF0000"
                                : undefined,
                        highlight:
                            item.polarity !== null
                                ? item.polarity === "positive"
                                    ? "#6BC581"
                                    : "#AF0000"
                                : undefined,
                    },
                    width: (item.strength ?? 1) * 1,
                    dashes: item.confidence !== null ? [10, (10 - item.confidence) * 2.5] : undefined,
                })
            });

            setDataset({ "edges": new DataSet([...edges.get()]), "nodes": new DataSet([...nodes.get()]) });
            const loadedNetwork = new Network(containerRef.current, dataset, options)
            setNetwork(loadedNetwork);
            dataset.nodes.forEach((item: any) => {
                if (loadedNetwork && dataset.nodes.length > 0 && dataset.edges.length > 0) {
                    const edges = loadedNetwork.getConnectedEdges(item.id);
                    const base_size = 15;
                    const edges_count = edges.length;
                    const final_size = base_size + (edges_count * 2);
                    dataset.nodes.update({
                        id: item.id, label: item.label, title: item.title, uri: item.uri, color: {
                            background: KnowledgeGraphColors.DEFAULT,
                            border: KnowledgeGraphColors.DEFAULT,
                            highlight: {
                                background: KnowledgeGraphColors.DEFAULT,
                                border: KnowledgeGraphColors.DEFAULT
                            },
                        },
                        size: final_size
                    });
                }
            });
            loadedNetwork.setData(dataset);
            setNetwork(loadedNetwork)

        }
    }, [apiCallResults]);


    useEffect(() => {

        if (!network) {
            return;
        }
        network.on('hold', (params) => {

        });

        network.on('dragEnd', (params) => {
            // reset current selection after drag ends
            if (network)
                network.setSelection(selection);
        });

        network.on('oncontext', (params) => {
            params.event.preventDefault();
            if (network)
                var nodeId = network.getNodeAt(params.pointer.DOM);
        });


        dataset.nodes.forEach((item: any) => {
            if (network && dataset.nodes.length > 0 && dataset.edges.length > 0) {
                    const edges = network.getConnectedEdges(item.id);
                    const base_size = 15;
                    const edges_count = edges.length;
                    const final_size = base_size + (edges_count * 2);
                    dataset.nodes.update({
                        id: item.id, label: item.label, title: item.title, uri: item.uri, color: {
                                background: KnowledgeGraphColors.DEFAULT, 
                                border: KnowledgeGraphColors.DEFAULT,
                                highlight: { 
                                    background: KnowledgeGraphColors.DEFAULT, 
                                    border: KnowledgeGraphColors.DEFAULT 
                                },
                            },
                            size: final_size
                    });
            }
        });
        network.setData(dataset)

    }, [network]);

    useEffect(() => {
        if (network) {
            //First we clear the current onclick
            network.off('click');

            // Now we add the new onclick
            network.on('click', (params) => {
                handleClick(params);
            });

            if (selectionTool !== SelectionTool.NewObject) {
                setShowObjectMenu(false);
            }
        }
    }, [selectionTool, showNewObjectMenu, newRelationCreating, network]);

    return (
        <>
            <div ref={containerRef} style={{ height: '', width: '' }} />
            {showNewObjectMenu && <CreateObject x={newObjectPosition.x} y={newObjectPosition.y} showNewObjectMenu={setShowObjectMenu} />}
            {showNewRelation && <CreateRelation CreateRelation={newRelationCreating} showNewRelationMenu={setShowNewRelation} />}
        </>
    );
};

export default KnowledgeGraph;