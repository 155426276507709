import axios from 'axios';
const BASE_URL= process.env.REACT_APP_CLUSTER ? `${process.env.PUBLIC_URL}/api` : 'http://localhost:8000/api';

// For Login component
export default axios.create({
    baseURL: BASE_URL
});

// For all other components because a token should be added to via interceptors
export const api = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }
    // with credentials true if we use a http only cookie
});