import useApi from '../hooks/useApi';
import { Alert, Button, Modal, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import useNetwork from '../hooks/useNetwork';
import { ShowPage } from "../enums/PanelType";
import { useParams } from 'react-router-dom';

const URL_OBJECT = '/object/';
const URL_NEW_OBJECT = '/add-new-object';

interface AddOrChangeNodeProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    showPage: ShowPage;
  }

const AddOrChangeNode: React.FC<AddOrChangeNodeProps> = ({showModal, setShowModal, showPage}) => {
    const [selectedType, setSelectedType] = useState("owl:Capability");
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [errMsg, setErrMsg] = useState<string>('');
    const [showSpinner, setShowSpinner] = useState(false);
    const [types, setTypes] = useState<string[]>();
    const {selection, dataset} = useNetwork();
    const [modalTitle, setModalTitle] = useState("Object toevoegen");
    let { objectId } = useParams<{ objectId: string }>();
    const handleClose = () => setShowModal(false);
    const api = useApi();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            setShowSpinner(true);
            setErrMsg('');

            if(showPage == ShowPage.AddNode) {
                saveNode();
            } else if(showPage == ShowPage.ChangeNode) {
                updateNode();
            }
        } finally {
            setShowSpinner(false);
        }
    }

    const updateNode = async() => {
        // const selectedNode: any = dataset.nodes.get(selection.nodes[0]);
        await api.put(URL_OBJECT + objectId, {type: selectedType, title: title, label: title, description: description})
            .then((response) => {
                // setRefreshGraph(prev => !prev);
                setShowModal(false);
            }).catch ((error) => {
                setErrMsg('Er is iets misgegaan, probeer het later opnieuw.');
            });
    }

    const saveNode = async() => {
        await api.post(URL_NEW_OBJECT, {type: selectedType, title: title, description: description})
            .then((response) => {
                // setRefreshGraph(prev => !prev);
                setShowModal(false);
            }).catch ((error) => {
                setErrMsg('Er is iets misgegaan, probeer het later opnieuw.');
            });
    }

    useEffect(() => {
        const setData = async () => {
            api.get("/ontology_types")
                .then((response) => {
                    setTypes(response.data.ontology_types);
                }).catch ((error) => {
                    setErrMsg('Er is iets misgegaan');
                });

            if(showPage == ShowPage.ChangeNode) {
                setModalTitle("Object aanpassen");
                const selectedNode: any = dataset.nodes.get(selection.nodes[0]);
                const res = await api.get(URL_OBJECT + objectId);
                setSelectedType(res.data.type);
                setTitle(res.data.title);
                setDescription(res.data.description);
            }
        };
        setData();
    }, [showPage]);
    
    return (
        <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit} className='row justify-content-center'>
                {errMsg && 
                    <div className="row mb-2">
                        <Alert variant="danger">{errMsg}</Alert>
                    </div>
                }
                <div className="row">
                    Type: 
                    <select name="type" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                        {types?.map((type: string) => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className="row mt-2">
                    Titel: <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required/>
                </div>
                <div className="row mt-2">
                    Beschrijving: <textarea name="description"  value={description} onChange={(e) => setDescription(e.target.value)} required/>
                </div>
                <div className="row mt-4">
                    <div className='col' >
                        <Button variant="secondary" onClick={handleClose}>Afbreken</Button>
                    </div>
                    <div className='col text-end' >
                        <Button variant="secondary" type='submit'>
                            {showSpinner && <Spinner as="span" animation="border" size="sm" className="me-1" /> }
                            Opslaan</Button>
                    </div>
                </div>
            </form>
        </Modal.Body>
      </Modal>
    );
};

export default AddOrChangeNode;