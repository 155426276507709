import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import messageText from '../data/text.json';
import useApi from '../hooks/useApi';
import useNetwork from '../hooks/useNetwork';
import { useParams } from 'react-router-dom';

interface RemoveModalProps {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    setShowRightPanel: Dispatch<SetStateAction<boolean>>;
}

const RemoveModal: FC<RemoveModalProps> = ({showModal, setShowModal, setShowRightPanel}) => {
    const api = useApi();
    const onHide = () => {setChecked(false); setShowModal(false); }
    const [checked, setChecked] = useState(false);
    const [removeDisabled, setRemoveDisabled] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const { selection } = useNetwork();
    const objectId = useParams<{ objectId: string }>().objectId;

    // When checked is changed, so must the remove button.
    useEffect(() => {
      setRemoveDisabled(!checked);
    }, [checked]);
    
    const RemoveData = async () => {
      setShowSpinner(true);
      let message = messageText.removeModal.error;
      
      // const selected: any = dataset.nodes.get(selection.nodes[0]);
      if(objectId) {
        await api.delete('/object/' + objectId +'/delete')
          .then((response) => {
            if(response.status == 200) {
              message = messageText.removeModal.succes;
              // setRefreshGraph(prev => !prev);
              setShowRightPanel(false);
            } else {
              console.log(response);
            }
          }).catch ((error) => {
            console.log(error);
          });
      }
      // TODO add message to alertbox, so show user something went wrong or node is deleted.
      // alert(message);
      onHide();
      setShowSpinner(false);
    }

    return (
      <Modal show={showModal} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {messageText.removeModal.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{messageText.removeModal.node.body}</p>
          <p>{messageText.removeModal.body}</p>
          <Form.Check type={"checkbox"} id="removeCheckbox" label={messageText.removeModal.node.checkbox} checked={checked} onChange={() => setChecked(!checked)} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>{messageText.removeModal.buttonBack}</Button>
          <Button onClick={RemoveData} disabled={removeDisabled}>
            {showSpinner && <Spinner as="span" animation="border" size="sm" className="me-1"/> }
            {messageText.removeModal.buttonContinue}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  
interface RemoveButtonProps {
  setShowRightPanel: Dispatch<SetStateAction<boolean>>;
}

const RemoveButton: FC<RemoveButtonProps> = ({setShowRightPanel}) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <button onClick={() => setShowModal(true)}>Verwijder node</button>
            <RemoveModal showModal={showModal} setShowModal={setShowModal} setShowRightPanel={setShowRightPanel}/>
        </>
    );
};

export default RemoveButton;