import { Button, Container, Offcanvas, Spinner } from "react-bootstrap";
import EdgeInformation from "./EdgeInformation";
import NodeInformation from "./NodeInformation";
import RemoveButton from "../RemoveButton";
import { SelectionPanel } from "../../enums/SelectionPanel";
import { useEffect } from "react";

interface RightPanelProps {
  showRightPanel: boolean;
  setShowRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
  typeRightPanel: SelectionPanel;
}

const RightPanel: React.FC<RightPanelProps> = ({ showRightPanel, setShowRightPanel, typeRightPanel }) => {

  const handleClose = () => setShowRightPanel(false);
  const backdrop = false;

  useEffect(() => {
    console.log(typeRightPanel)
  }, [setShowRightPanel, showRightPanel]);

  return (
    <>
      <Offcanvas show={showRightPanel} onHide={handleClose} placement="end" backdrop={backdrop} style={{ width: '500px' }}>
        {typeRightPanel === SelectionPanel.NODE_INFORMATION && (<NodeInformation setShowRightPanel={setShowRightPanel} showRightPanel={showRightPanel} />)}
        {typeRightPanel === SelectionPanel.EDGE_INFORMATION && (<EdgeInformation setShowRightPanel={setShowRightPanel} showRightPanel={showRightPanel} />)}
      </Offcanvas>
    </>
  );
}

export default RightPanel;