import Offcanvas from 'react-bootstrap/Offcanvas';
import Form from 'react-bootstrap/Form';

interface OffCanvasExampleProps {
  show: boolean;
  [key: string]: any;
}

// onMouseOut={() => props.showFunction(false)}>

function StatsMenu({ show, ...props }: OffCanvasExampleProps) {

    return (
      <>
        <Offcanvas className="secondary-bg-color-grey" style={{ marginLeft: document.getElementById('left-menu-panel')?.offsetWidth || 40, zIndex: 14}} show={show}  {...props} backdrop={false}>
          <Offcanvas.Header closeButton onClick={() => props.showFunction(false)} >
            <Offcanvas.Title>Visualisatie</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='row me-4 ms-4'>
                <div className='col-lg-12 align-self-center third-bg-color-grey circular-border p-2 pt-4 pb-4'>
                    <div className='col-lg-12 justify-content-center d-flex'>
                        <p className='text-light'>Size nodes by</p>
                    </div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 justify-content-center d-flex'>
                              <div className='circle main-bg-color col-8 justify-content-center d-flex' style={{aspectRatio: 1}}>
                                <i className="fa-solid fa-circle-nodes ft-m text-light center-vertically" ></i>
                              </div>
                            </div>
                            <div className='col-lg-6 col-md-6 justify-content-center d-flex'>
                              <div className='circle main-bg-color col-8 justify-content-center d-flex' style={{aspectRatio: 1}}>
                                  <i className="fa-solid fa-circle-nodes ft-m text-light center-vertically"></i>
                              </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-lg-6 col-md-6 justify-content-center d-flex text-light text-center' style={{fontSize: '0.8rem'}}>
                                Number of incoming connections
                            </div>
                            <div className='col-lg-6 col-md-6 justify-content-center d-flex text-light text-center' style={{fontSize: '0.8rem'}}>
                                Number of outgoing connections
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row me-4 ms-4 mt-5'>
                <div className='col-lg-12 align-self-center third-bg-color-grey circular-border p-2 pt-4 pb-4'>
                    <div className='col-lg-12 justify-content-center d-flex'>
                        <p className='text-light'>See connections</p>
                    </div>
                    <div className='container-fluid'>
                        <div className='row'>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row me-4 ms-4 mt-5'>
                <div className='col-lg-12 align-self-center'>

                  <div className="row" style={{height: '30px'}}>
                      <div className='col-lg-8 third-bg-color-grey circular-border justify-content-center d-flex text-light' style={{height: '30px'}}>
                          <p className='text-light my-auto'>Enable coloring</p>
                      </div>
                      <div className='col-lg-3 offset-1'>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </div>
                  </div>
                </div>
            </div>

            <div className='row me-4 ms-4 mt-5'>
                <div className='col-lg-12 align-self-center third-bg-color-grey circular-border p-2 pt-4 pb-4'>
                    <div className='col-lg-12 justify-content-center d-flex'>
                        <p className='text-light'>Colour nodes by</p>
                    </div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 justify-content-center d-flex'>
                              <div className='circle main-bg-color col-8 justify-content-center d-flex' style={{aspectRatio: 1}}>
                                <i className="fa-solid fa-hexagon-nodes ft-m text-light center-vertically" ></i>
                              </div>
                            </div>
                            <div className='col-lg-6 col-md-6 justify-content-center d-flex'>
                              <div className={'circle main-bg-color col-8 justify-content-center d-flex'} style={{aspectRatio: 1}}>
                                  <i className="fa-solid fa-hexagon-nodes-bolt ft-m text-light center-vertically"></i>
                              </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-lg-6 col-md-6 justify-content-center d-flex text-light text-center' style={{fontSize: '1.0rem'}}>
                                Cluster
                            </div>
                            <div className='col-lg-6 col-md-6 justify-content-center d-flex text-light text-center' style={{fontSize: '1.0rem'}}>
                                Type
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  
}

export default StatsMenu;