export const options = {
    physics: {
        
        solver: "repulsion",
        repulsion: {
            nodeDistance: 160,
            springLength: 400,
            springConstant: 0.05,
        },
        stabilization: {
            enabled: true,
            iterations: 100,
        },
    },
    nodes: {
        shape: 'dot',
        scaling: {
            min: 10,
            max: 30,
        },
        font: {
            size: 8,
            face: 'Roboto',
            color: 'rgb(255, 255, 255)',
            strokeWidth: 2,
            strokeColor: 'rgb(0, 0, 0)',
        },
        color: {
            hover: {
                background: 'rgba(255,87,34,1)',
                border: 'rgba(255,87,34,1)'
            }
        }
    },
    edges: {
        width: 0.50,
        color: { inherit: false },
        arrowStrikethrough: false,
        chosen: false,
        smooth: true,
        arrows: {
            to: {
                enabled: true,
                scaleFactor: 0.5
            }
        },
        font: {
            size: 8,
            face: 'Roboto',
        }
        // smooth: {
        //     enabled: true,
        //     type: 'continuous'
        // }
    },
    layout: {
        improvedLayout: true,
    },
    interaction: {
        hover: false,
        hideEdgesOnDrag: false,
        hideEdgesOnZoom: false
    }
};
