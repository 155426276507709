import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const Protected = () => {
    const { isAuthenticated } = useAuth();

    return (
        // Add role based authorisation in the future
        isAuthenticated ? <Outlet /> : <Navigate to='/login' replace />
    )
}

export default Protected;