export enum PanelType {
    Stats,
    Settings
}

export enum ShowPage {
    AddNode,
    AddEdge,
    ChangeNode,
    ChangeEdge
}