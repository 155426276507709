import StatsMenu from './StatsMenu';
import { PanelType } from '../../enums/PanelType';
import SettingsMenu from './SettingsMenu';

interface OffCanvasExampleProps {
  show: boolean;
  panel_type: PanelType;
  [key: string]: any;
}

// onMouseOut={() => props.showFunction(false)}>

function LeftStatsPanel({ show, panel_type, ...props }: OffCanvasExampleProps) {

  if (panel_type == PanelType.Stats) {
    return (
      <StatsMenu show={show} showFunction={props.showFunction}/>
    );
  } else if (panel_type == PanelType.Settings) {
    return (
      <SettingsMenu show={show} showFunction={props.showFunction}/>
    );
  }
  return (
    <StatsMenu show={show} showFunction={props.showFunction}/>
  );
}

export default LeftStatsPanel;