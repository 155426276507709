import { NetworkProvider } from "../contexts/NetworkProvider";
import KnowledgeGraph from "./knowledge-graph/KnowledgeGraph";
import LeftMenuPanel from "./left-menu/LeftMenuPanel";
import RightPanel from "./RightPanel/RightPanel";
import OptionsMenu from "./OptionsMenu";
import { useState } from "react";
import SelectionMenuBottom from "./selection-menu/SelectionMenuBottom";
import { SelectionPanel } from '../enums/SelectionPanel';
import SearchTopMenu from "./search-menu/SearchTopMenu";
import { Offcanvas } from "react-bootstrap";

interface DashboardProps {
    showPanel: boolean;
    selectedTypeRightPanel: SelectionPanel;
}

const Dashboard: React.FC<DashboardProps> = ({ showPanel = true, selectedTypeRightPanel = SelectionPanel.NONE }) => {
    const [showRightPanel, setShowRightPanel] = useState<boolean>(showPanel);
    const [typeRightPanel, setTypeRightPanel] = useState<SelectionPanel>(selectedTypeRightPanel);

    return (
        // Add filter component
        // Add search component
        <>
            <LeftMenuPanel />
                <SearchTopMenu setShowRightPanel={setShowRightPanel} />


            <NetworkProvider>
                <KnowledgeGraph setShowRightPanel={setShowRightPanel} setTypeRightPanel={setTypeRightPanel} />
                <RightPanel showRightPanel={showRightPanel} setShowRightPanel={setShowRightPanel} typeRightPanel={selectedTypeRightPanel} />
                <SelectionMenuBottom />
                <OptionsMenu />
            </NetworkProvider>

        </>
    );
};

export default Dashboard;