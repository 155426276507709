import { Button, CloseButton } from "react-bootstrap";
import "./CreateObject.css"
import { useEffect, useState } from "react";
import { Icon0Circle } from "react-bootstrap-icons";
import KnowledgeGraph from "./KnowledgeGraph";
import { KnowledgeGraphColors } from "../../enums/KnowledgeGraphColors";
import Dropdown from 'react-bootstrap/Dropdown';

interface CreateRelation {
  CreateRelation: any;
  showNewRelationMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

// onMouseOut={() => props.showFunction(false)}>
function CreateNewRelation({ CreateRelation, showNewRelationMenu }: CreateRelation) {
  const [style, setStyle] = useState({});
  const left = (CreateRelation.x || 0) + (document.getElementById('left-menu-panel')?.offsetWidth || 0);
  const top = (CreateRelation.y || 0);

  useEffect(() => {
    const left = (CreateRelation.x || 0) + (document.getElementById('left-menu-panel')?.offsetWidth || 0);
    const top = (CreateRelation.y || 0);
  }, [CreateRelation]);

  const closeObjectView = () => {
    showNewRelationMenu(false);
  }


  return (
    <>
        <div className="new-object primary-bg-color-grey rounded shadow" style={{transition: "transform 0.3s ease-in-out", left: left, top: top}}> 
                <form className="p-3">
                    <div className="row">
                        <div className="col-lg-1">
                            <i className="fa-solid fa-code-pull-request text-dark ft-m" />
                        </div>
                        <div className="col-lg-10 justify-content-center d-flex">
                            <h5>New Relation</h5>
                        </div>
                        <div className="col-lg-1">
                            <CloseButton onClick={() => closeObjectView()}/>
                        </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 mt-2 justify-content-center d-flex">
                        {CreateRelation.data_object_1.label}
                      </div>
                      <div className="col-lg-4 mt-2 justify-content-center d-flex">
                        <i className="fa-solid fa-arrow-right text-dark ft-m" />
                      </div>
                      <div className="col-lg-4 mt-2 justify-content-center d-flex">
                        {CreateRelation.data_object_2.label}
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-2 mt-2 justify-content-center d-flex">
                      </div>
                        <Dropdown className="col-lg-8">
                          <Dropdown.Toggle variant="success" className="col-lg-8 outline-0 rounded-pill secondary-bg-color-grey border-0 w-100 py-2 ps-3">
                            Dropdown Button
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      <div className="col-lg-2 mt-2 justify-content-center d-flex">
                      </div>
                    </div>
                    <Button className="mt-2 w-100" style={{backgroundColor: KnowledgeGraphColors.SELECTED, borderColor: KnowledgeGraphColors.SELECTED}}>Opslaan</Button>
                </form>
        </div>
        <div className="new-object-circle circle-node shadow" style={{width: "30px", height:"30px", left: left - 20, top: top - 20, backgroundColor: KnowledgeGraphColors.SELECTED}}> 

        </div>
    </>
  )
}

export default CreateNewRelation;