import { useEffect, useState } from "react";
import { Button, Col, Container, ListGroup, Offcanvas, Row, Spinner } from "react-bootstrap";
import useNetwork from "../../hooks/useNetwork";
import useApi from "../../hooks/useApi";
import { setLabelForNode } from "../../helpers/helper_functions";
import { useLocation, useParams } from 'react-router-dom';

const URL = '/impact/'

interface RightPanelProps {
    showRightPanel: boolean;
    setShowRightPanel: React.Dispatch<React.SetStateAction<boolean>>;
  }

const EdgeInformation : React.FC<RightPanelProps>  = ({ showRightPanel, setShowRightPanel}) => {

    const [isLoading, setIsLoading] = useState(true);

    const { selection, dataset, network} = useNetwork();
    const api = useApi();

    const [label, setLabel] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    let { edgeId } = useParams<{ edgeId: string }>();
    const [strength, setStrength] = useState<number>(0);
    const [confidence, setConfidence] = useState<number>(0);
    const [polarity, setPolarity] = useState<string>('');
    const [causeLabel, setCauseLabel] = useState<string>('');
    const [causeDescription, setCauseDescription] = useState<string>('');
    const [effectLabel, setEffectLabel] = useState<string>('');
    const [effectDescription, setEffectDescription] = useState<string>('');
    const [res, setApiCallResults] = useState<any>(null);
    const [edgeIsImpact, setEdgeIsImpact] = useState<boolean>(false);


    useEffect(() => {
        if(edgeId !== "null") {
            console.log("Change value")
            api.get(URL + edgeId).then((res) => {
                setApiCallResults(res);
            }).catch((err) => {
                console.log(err);
            });
        } else {
            setLabel("API call here");
            setType('Regular');
            setEdgeIsImpact(false);
        }
    }, [edgeId]);

    useEffect(() => {


        setIsLoading(true);

        try {
            // const res = await api.get(URL + edgeId);


            res.data.title ? setLabel(res.data.title) : setLabel('N.t.b.');

            setType('Impact');

            res.data.weight.strength ? setStrength(res.data.weight.strength) : setStrength(0);
            res.data.weight.confidence ? setConfidence(res.data.weight.confidence) : setConfidence(0);
            res.data.weight.polarity ? res.data.weight.polarity === 'positive' ? setPolarity('Positief') : setPolarity('Negatief') : setPolarity('N.t.b.');

            res.data.description ? setDescription(res.data.description) : setDescription('N.t.b.');


            // Set cause information
            res.data.causeLabel ? setCauseLabel(res.data.causeLabel) : setCauseLabel('N.t.b.');
            res.data.causeDescription ? setCauseDescription(setLabelForNode(res.data.causeDescription, 10)) : setCauseDescription('N.t.b.');

            // Set effect information
            res.data.effectLabel ? setEffectLabel(res.data.effectLabel) : setEffectLabel('N.t.b.');
            res.data.effectDescription ? setEffectDescription(setLabelForNode(res.data.effectDescription, 10)) : setEffectDescription('N.t.b.');

            setEdgeIsImpact(true);
                
        } catch (err) {

            console.log(err);

        } finally {

            setIsLoading(false);

        }

    }, [res]);

    return (
        <>
            {isLoading ? (<Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh', color: '#BFBFBF' }}>
                <Spinner animation="border" />
            </Container>) : (
                <>
                    <Offcanvas.Header style={{ background: '#BFBFBF', paddingRight: '44px', paddingBottom: '0px' }} closeButton></Offcanvas.Header>
                    <Offcanvas.Header style={{ background: '#BFBFBF', paddingTop: '0px' }}>
                        <Offcanvas.Title><h3 style={{ padding: '12px' }}>{label}</h3></Offcanvas.Title><br />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Container fluid >
                            <Row className='p-3' style={{ background: '#BFBFBF' }}>
                                <Container>
                                    <Row>
                                        <Col xs={5}>Type:</Col>
                                        <Col xs={5} className="text-end">{type}</Col>
                                        <Col xs={2}></Col>
                                    </Row>
                                    {edgeIsImpact && (<>
                                        <Row>
                                            <Col xs={5}>Sterkte:</Col>
                                            <Col xs={5} className="text-end">{(strength * 100) / 10}%</Col>
                                            <Col xs={2}></Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5}>Zekerheid:</Col>
                                            <Col xs={5} className="text-end">{(confidence * 100) / 10}%</Col>
                                            <Col xs={2}></Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5}>Polariteit:</Col>
                                            <Col xs={5} className="text-end">{polarity}</Col>
                                            <Col xs={2}></Col>
                                        </Row>
                                    </>
                                    )}
                                </Container>
                            </Row>

                            {edgeIsImpact && (<>
                                <Row className='p-3 mt-1' style={{ background: '#EAEAEA' }}>
                                    <Container>
                                        <Row><h5>Beschrijving:</h5></Row>
                                        <Row><p>{description}</p></Row>
                                    </Container>
                                </Row>
                            </>
                            )}

                            {edgeIsImpact && (<>
                                <Row className='p-3 mt-1' style={{ background: '#EAEAEA' }}>
                                    <Container>
                                        <Row>
                                            <ListGroup>
                                                <ListGroup.Item className="d-flex align-items-center justify-content-between" style={{ background: '#EAEAEA', border: 'none', paddingLeft: '12px' }}>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                backgroundColor: 'rgba(255,87,34,1)',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: 'white',
                                                                marginRight: '10px',
                                                                flexShrink: 0
                                                            }}
                                                        >
                                                        </div>
                                                        <div>
                                                            <span><h5>{causeLabel}</h5></span>
                                                            <div>{causeDescription}</div>
                                                        </div>
                                                    </div>
                                                    <Button className="p-0" style={{ background: '#EAEAEA', border: 'none' }}><span className="three-dots">⋮</span></Button>
                                                </ListGroup.Item>
                                                <div className="d-flex align-items-center my-2">
                                                    <i className="bi bi-arrow-down fs-1" style={{ marginLeft: '12px', color: polarity === "Positief" ? "#6BC581" : "#AF0000" }}></i>
                                                </div>
                                                <ListGroup.Item className="d-flex align-items-center justify-content-between" style={{ background: '#EAEAEA', border: 'none', paddingLeft: '12px' }}>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                backgroundColor: 'rgba(255,87,34,1)',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: 'white',
                                                                marginRight: '10px',
                                                                flexShrink: 0
                                                            }}
                                                        >
                                                        </div>
                                                        <div>
                                                            <span><h5>{effectLabel}</h5></span>
                                                            <div>{effectDescription}</div>
                                                        </div>
                                                    </div>
                                                    <Button className="p-0" style={{ background: '#EAEAEA', border: 'none' }}><span className="three-dots">⋮</span></Button>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Row>
                                    </Container>
                                </Row>
                            </>
                            )}
                        </Container>
                    </Offcanvas.Body>
                </>
            )}
        </>
    );
};

export default EdgeInformation;