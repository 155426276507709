import { useRef, useState, useEffect } from 'react';
import axios from '../utils/axios';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom'

const URL = '/login';

const Login = () => {
    const { setUserInformation } = useAuth();

    const navigate = useNavigate();
    
    const userRef = useRef<HTMLInputElement>(null);
    const errRef = useRef<HTMLInputElement>(null);

    const [user, setUser] = useState<string>('');
    const [pwd, setPwd] = useState<string>('');
    const [errMsg, setErrMsg] = useState<string>('');

    useEffect(() => {
        if(userRef.current){
            userRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        try {
            const res = await axios.post(URL, {email: user, password: pwd});

            //200 OK
            const role = res.data.extra.role;
            const token = res.data.token;
            
            //set user information in global context (in memory)
            setUserInformation(user, role, token);
           
            setUser('');
            setPwd('');

            navigate('/dashboard', {replace: true});
        } catch (err) {
            //422 Unprocessable Entity
            //401 Unauthorized
          
            setErrMsg('Er is iets misgegaan');
            
            if(errRef.current){
                errRef.current.focus();
            }
        }    
    }

    return (
        <section className='d-flex align-items-center justify-content-center py-4 bg-body-tertiary w-100 h-100 bg-light'>
            <div className="card p-4 shadow container col-8 col-sm-8 col-md-7 col-lg-6 col-xl-3 mx-auto">
                <div className="row">
                    <form onSubmit={handleSubmit} className='row justify-content-center'>
                        <div className='d-flex justify-content-center mb-4'>
                            <img src='favicon.svg' alt='logo' width="40" height="40" className='img-fluid me-2' />
                            <p className='h2 w-100 mb-0'>Futures</p>
                        </div>
                        <div data-mdb-input-init className="form-outline">
                            <label htmlFor="username" className='form-label mb-2'>
                                <p className='h6 fw-bold'>Email-adres</p></label>
                            <input 
                                type="text" 
                                id="username"
                                ref={userRef}
                                className='form-control mb-2' 
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required 
                            /> 
                        </div>
                        <div data-mdb-input-init className="form-outline">
                            <label htmlFor="password" className='form-label fw-bold mb-2'>
                                Wachtwoord
                            </label>
                            <input 
                                type="password" 
                                id="password" 
                                className='form-control mb-2' 
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required 
                            />
                        </div>
                        <div className='form-outline mt-4'>
                            <button className="btn w-100 btn-dark"> Inloggen </button>
                        </div>
                    </form>
                </div>
                <p 
                    className={errMsg ? "errmsg" : "offscreen"} 
                    ref={errRef} 
                    aria-live="assertive"
                >
                    {errMsg}
                </p>
            </div>
        </section>
    );

}

export default Login;