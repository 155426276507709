import { createContext, useState, ReactNode, useEffect, useRef, MutableRefObject, Dispatch, SetStateAction } from 'react';
import { IdType, Network } from 'vis-network';
import { DataSet } from 'vis-data';
import { SelectionTool } from '../enums/SelectionTool';

interface NetworkContextType {
    network: Network | null;
    setNetwork: React.Dispatch<React.SetStateAction<Network | null>>;
    dataset: {"nodes": DataSet<{}>, "edges": DataSet<{}>};
    setDataset: React.Dispatch<React.SetStateAction<{"nodes": DataSet<{}>, "edges": DataSet<{}>}>>;
    selection: {nodes: IdType[], edges: IdType[]};
    setSelection: React.Dispatch<React.SetStateAction<{nodes: IdType[], edges: IdType[]}>>
    selectionTool: SelectionTool
    setSelectionTool: React.Dispatch<React.SetStateAction<SelectionTool>>
    refreshGraph: boolean;
    setRefreshGraph: Dispatch<SetStateAction<boolean>>;
}

const NetworkContext = createContext<NetworkContextType | undefined>(undefined);

export const NetworkProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [network, setNetwork] = useState<Network | null>(null);
    const [dataset, setDataset] = useState<{"nodes": DataSet<{}>, "edges": DataSet<{}>}>({"nodes": new DataSet<{}>([]), "edges": new DataSet<{}>([])});
    const [selection, setSelection] = useState<{nodes: IdType[], edges: IdType[]}>({nodes: [] as IdType[], edges: [] as IdType[]});
    const [selectionTool, setSelectionTool] = useState<SelectionTool>(SelectionTool.Select);
    const [refreshGraph, setRefreshGraph] = useState(false);

    return (
        <NetworkContext.Provider value={{ network, setNetwork, dataset, setDataset, selection, setSelection, selectionTool, setSelectionTool, refreshGraph, setRefreshGraph}}>
            { children }
        </NetworkContext.Provider>
    );

};

export default NetworkContext;
