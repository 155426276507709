import { createContext, useState, ReactNode, useEffect } from 'react';

interface AuthContextType {
    user: string;
    role: string;
    token: string;
    isAuthenticated: boolean;
    setUserInformation: (user: string, role: string, token: string) => void;
    removeUserInformation: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const setUserInformation= (user: string, role: string, token: string) => {
        setUser(user);
        setRole(role);
        setToken(token);
        setIsAuthenticated(true);

        //add token to local storage: unsafe! needs to be removed in the future
        localStorage.setItem('token', token);
    };

    const removeUserInformation = () => {
        setUser('');
        setRole('');
        setToken('');
        setIsAuthenticated(false);

        //remove token from local storage: unsafe! needs to be removed in the future
        localStorage.removeItem('token');
    }
    
    return (
        <AuthContext.Provider value={{ user, role, token, isAuthenticated, setUserInformation, removeUserInformation }}>
            { children }
        </AuthContext.Provider>
    );
};

export default AuthContext;