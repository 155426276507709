import './App.css';
import './components/RightPanel/RightPanel.css';
import { Routes, Route, Navigate } from 'react-router-dom';

import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Help from './components/Help';
import Protected from './components/Protected';
import Persistend from './components/Persistend';
import 'bootstrap/dist/css/bootstrap.css';
import { SelectionPanel } from './enums/SelectionPanel';

const App = () => {
  return (
    <Routes>
       {/* public routes*/}
      <Route path='/login' element={<Login />} />

      {/* Add route for a navbar in the future */}
      
      <Route element={<Persistend />} >
        {/* protected routes*/}
        <Route element={<Protected />}>
          <Route path='/dashboard' element={<Dashboard showPanel={false} selectedTypeRightPanel={SelectionPanel.NONE}/>} />
          <Route path='/object/:objectId' element={<Dashboard showPanel={true} selectedTypeRightPanel={SelectionPanel.NODE_INFORMATION}/>} />
          <Route path='/edge/:edgeId' element={<Dashboard showPanel={true} selectedTypeRightPanel={SelectionPanel.EDGE_INFORMATION}/>} />
          <Route path='/help' element={<Help />} />
        </Route>
      </Route>

      {/* not existing*/}
      <Route path="*" element={<Navigate to="/dashboard" replace/>} />
    </Routes>
  );

}

export default App;