import { useContext } from 'react';
import NetworkContext from '../contexts/NetworkProvider';

const useNetwork =  () => {
    const context = useContext(NetworkContext);
    if (context === undefined) { 
        throw new Error('useNetwork must be used within an NetworkProvider');
    }
    return context;
};

export default useNetwork;